import Blog from "./Blog";
import Footer from "../footer/socialfollow";

export default function Bloghome() {
  return (
    <div>
      <div className="h-screen">
        <Blog />
      </div>
      <div className="hidden lg:block">
        <Footer />
      </div>
    </div>
  );
}
