import React from 'react'
import Map from './map'
function App() {
  return (
    <div>
      <div></div>
      <div className="hidden lg:block">
        <Map />
      </div>
    </div>
  )
}

export default App
